@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600&family=Outfit:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply transition-all ease-in-out;
}

body {
  @apply font-['Outfit'] bg-white dark:bg-slate-800;
}

.page-section {
  @apply w-full flex flex-col items-center justify-center py-24 lg:py-32;
}

.icon {
  @apply w-6 h-6;
}

.icon-sm {
  @apply w-5 h-5;
}
